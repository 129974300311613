.recharts-pie-label-line {
  display: none;
}

.muirtl-1nykogk-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  background-color: "none";
}

.ltr-input input,
input.ltr-input {
  direction: ltr !important;
}

.google-map-container {
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #ccc;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25), 0 3px 3px rgba(0, 0, 0, 0.22);
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc,
.gm-svpc,
.gm-style-mtc {
  display: none !important;
}
.gm-style-iw + div {
  display: none !important;
}
.gm-ui-hover-effect {
  display: none !important;
}
.gm-style-iw.gm-style-iw-c {
  padding: 0;
}
.gm-style-iw-d {
  overflow: inherit !important;
  max-width: unset !important;
  max-height: unset !important;
}

.date-input.MuiPaper-root.MuiPaper-elevation {
  max-width: fit-content;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
  cursor: row-resize;
}

.gm-style .gm-style-iw-t::after {
  background: none;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333333dd;
  transform: translateX(-50%);
}

.gm-style .gm-style-iw-c {
  background: #333333dd;
}
